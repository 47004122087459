@charset "UTF-8";
.register-right-section[data-v-6fb35e06] {
  padding: 53px 40px;
}
.register-right-section[data-v-6fb35e06] p {
  color: #212121;
}
.register-right-section[data-v-6fb35e06] .custom-top p {
  margin-top: 0;
}
.register-right-section[data-v-6fb35e06] .custom-top > p:last-child {
  margin-bottom: 48px;
}
.register-right-section[data-v-6fb35e06] .list h3 {
  text-align: left;
}
.register-right-section[data-v-6fb35e06] h3 {
  text-align: center;
  padding: 0 0 20px;
  font-size: 1.87rem;
  font-weight: 500;
  color: #212121;
  line-height: 1.21886em;
  margin: 0;
}
.register-right-section[data-v-6fb35e06] ul {
  margin: 0;
  padding: 0;
}
.register-right-section[data-v-6fb35e06] ul li {
  list-style: none;
  color: #212121;
  font-size: 1rem;
  text-indent: -8px;
  padding-left: 20px;
}
.register-right-section[data-v-6fb35e06] ul li:not(:last-child) {
  padding-bottom: 16px;
}
.register-right-section[data-v-6fb35e06] ul li::before {
  content: "⚫";
  font-size: 0.3em;
  vertical-align: middle;
  display: inline-block;
  line-height: 0.4em;
  margin-top: -2px;
  color: #666;
  padding-right: 8px;
}
.register-right-section[data-v-6fb35e06] .features {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  max-width: 378px;
  margin: 0 auto;
  margin-top: 16px;
}
.register-right-section[data-v-6fb35e06] .features .feature {
  width: 50%;
  text-align: center;
  padding: 16px;
}
.register-right-section[data-v-6fb35e06] .features .feature p {
  text-align: center;
  line-height: 16px;
  font-size: 12px;
  font-weight: 400;
  color: #212121;
  margin: 0;
}
.register-right-section[data-v-6fb35e06] .features .feature picture {
  display: inline-block;
  width: 59px;
}
.register-right-section[data-v-6fb35e06] .features .feature picture img {
  width: 100%;
}
.register-right-section[data-v-6fb35e06] .features .feature h4 {
  font-family: "FSecureOffice";
  text-transform: uppercase;
  font-weight: bold;
  font-size: 13px;
  padding-top: 0px;
  color: #333333;
  line-height: 1.21886em;
  padding-bottom: 0.288em;
  margin: 0;
}