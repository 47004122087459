.two-col-box > div[data-v-7dc32e17] {
  background: #fff;
  -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
          box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
  max-width: 960px;
  min-width: 320px;
  margin: 0 auto;
  min-height: 300px;
}
.two-col-box.wide > div[data-v-7dc32e17] {
  max-width: 980px;
}
.two-col-box.even-wider > div[data-v-7dc32e17] {
  max-width: 1018px;
}
@media (min-width: 835px) {
.two-col-box > div[data-v-7dc32e17] {
    margin: -70px auto 100px auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-line-pack: stretch;
        align-content: stretch;
}
.two-col-box > div > div[data-v-7dc32e17] {
    width: 50%;
}
}